<template>
  <div class="user-evaluate">
    <div class="e-container" v-loading="loading">
      <div class="e-header">
        <div class="h-title">评价详情</div>
        <div class="h-info">快乐的你分享完美的使用心得~</div>
      </div>
      <div class="c-content">
        <!-- 商品资料 -->
        <div class="c-goods">
          <div class="g-img">
            <a href="#">
              <img :src="goodsList.img" alt="" />
            </a>
          </div>
          <div class="g-name">
            <a href="#"> {{ goodsList.goods_name }}</a>
          </div>
          <div class="g-price">
            <strong>¥{{ goodsList.goods_price }}</strong>
          </div>
        </div>
        <!--评价内容 -->
        <div class="c-eva">
          <!-- 显示日期 -->
          <div class="e-date">{{ evaluateInfo.add_time | dateFormat }}</div>
          <div class="e-icon">
            &nbsp;
            <el-rate
              v-model="evaluateInfo.goods_score"
              :colors="iconColors"
              disabled
            ></el-rate>
            <span> {{ evaluateInfo.goods_score }}分 </span>
          </div>
          <!-- 评论内容 -->
          <div class="e-content">{{ evaluateInfo.comment }}</div>
          <!-- 评论图片 -->
          <div class="e-img">
            <el-image
              :src="item"
              :preview-src-list="srcList"
              v-for="(item, index) in srcList"
              :key="index"
            ></el-image>
          </div>
          <!--删除按钮 -->
          <div class="c-btn">
            <el-button @click="deleteEvaluation()"> 删除评论 </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getEvaluateListApi, deleteEvaluateApi } from '@/api/reply.js'
  export default {
    name: "userHaveEvaluate",
    data() {
      return {
        id: "",
        oid: "",
        goodsList: {},
        evaluateInfo: {},
        iconColors: ['#e1251b', '#e1251b', '#e1251b'],
        srcList: [],
        loading: false
      }
    },
    created() {
      this.id = this.$route.query.id
      this.oid = this.$route.query.oid
      this.getEvaluation()
    },
    methods: {
      //获取商品评价列表数据
      async getEvaluation() {
        this.loading = true
        const { data: res } = await getEvaluateListApi({ id: this.id, oid: this.oid })
        if (res.message !== "ok") {
          return this.$message.error("获取商品评价列表数据失败")
        }
        this.goodsList = res.result.res
        this.evaluateInfo = res.result.info
        this.srcList = res.result.info.img
        this.loading=false
      },
      async deleteEvaluation() {
        const { data: res } = await deleteEvaluateApi({ id: this.oid })
        if (res.message !== "ok") {
          return this.$message.error('删除评论失败')
        }
        this.$router.go(-1)
      }
    },

  }
</script>
<style lang="less" scoped>
.user-evaluate {
  background: #f9f9f9;
  padding: 20px;
  min-height: 600px;

  .e-container {
    width: 1200px;
    margin: 0 auto;

    .e-header {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .h-title {
        font-size: 16px;
        font-weight: 600;
      }

      .h-info {
        color: #666;
      }
    }

    .c-content {
      min-height: 345px;
      background: #fff;
      display: flex;

      .c-goods {
        width: 322px;
        min-height: 345px;
        box-sizing: border-box;
        border-right: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 40px;

        .g-img {
          width: 100px;
          height: 100px;

          img {
            width: 100px;
            height: 100px;
          }
        }

        .g-price {
          strong {
            font-family: verdana;
            color: #666;
          }
        }
      }

      .c-eva {
        width: 878px;
        min-height: 345px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 20px 120px;
        display: flex;
        flex-direction: column;
        position: relative;

        .e-icon {
          display: flex;

          span {
            color: #e1251b;
            margin-top: 1px;
            font-weight: 600;
          }
        }

        .e-date {
          line-height: 30px;
          color: #999;
          margin: 5px 0px;
        }

        .e-content {
          margin: 20px 0px;
        }

        .e-img {
          display: flex;

          .el-image {
            width: 100px;
            height: 100px;
            margin-right: 10px;
          }
        }

        .c-btn {
          position: absolute;
          bottom: 8%;
          right: 18%;

          .el-button {
            background: #e1251b;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>