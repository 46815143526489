<template>
  <div class="apply-service" v-loading="loading">
    <div class="s-container">
      <!-- 仅退款 -->
      <div class="s-refund" v-if="isShowRefund==1">
        <div class="r-item i-hd">买家申请退款</div>
        <div class="r-item">
          <span>卖家处理退款申请</span>
        </div>
        <div class="r-item">
          <span>退款完毕</span>
        </div>
      </div>
      <!-- 退款退货 -->
      <div class="s-goods" v-if="isShowRefund==2">
        <div class="g-item g-hd">买家申请退款退货</div>
        <div class="g-item">
          <span>卖家处理退货申请</span>
        </div>
        <div class="g-item">
          <span>买家退货</span>
        </div>
        <div class="g-item">
          <span>退款完毕</span>
        </div>
      </div>
      <div class="s-main">
        <div class="m-content">
          <div class="c-goods">
            <div class="s-title">退款商品:</div>
            <div class="g-content">
              <div class="c-img">
                <img :src="ordergoods.img"/>
              </div>
              <ul class="c-text">
                <li>
                  {{ordergoods.goods_name}}
                </li>
                <li class="t-price">
                  <strong>{{ordergoods.real_price}}</strong>
                </li>
              </ul>
            </div>
          </div>
          <!-- 服务类型 -->
          <div class="c-service">
            <div class="c-title">服务类型:</div>
            <div class="s-content">
              <div
                :class="{ 's-current': isShowRefund==1 }"
                class="s-item"
                @click="handleRefund"
              >
                仅退款
              </div>
              <div
                :class="{ 's-current': isShowRefund==2 }"
                class="s-item"
                @click="handleBoth"
              >
                退货退款
              </div>
            </div>
          </div>
          <!-- form表单 -->
          <el-form class="r-container">
            <!--退款原因-->
            <el-form-item label="退款原因:" class="r-content" v-if="isShowRefund ==1">
              <el-select
                v-model="refundValue"
                placeholder="请选择"
                size="small"
                @change="refundchange"
              >
                <el-option
                  v-for="item in refundOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="item__error_refund el-form-item__error">{{rmes}}</div>
            </el-form-item>
            <!--退款退货原因-->
            <el-form-item label="退款原因:" class="r-content" v-if="isShowRefund ==2">
              <el-select v-model="bothValue" placeholder="请选择" size="small" @change="refundchange">
                <el-option
                  v-for="item in bothOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="item__error_refund el-form-item__error">{{rmes}}</div>
            </el-form-item>
            <!-- 退款金额 -->
            <el-form-item label="退款金额:" class="r-price">
              <el-input
                @input="fromChange"
                v-model="refundSum"
                placeholder="请输入退款金额"
                size="small"
              ></el-input>
              <div class="el-form-item__error">{{mes}}</div>
            </el-form-item>
            <!-- 退款说明-->
            <el-form-item label="退款说明:" class="r-checkbox">
              <el-input
                v-model="reasontext"
                type="textarea"
                maxlength="200"
                placeholder="退款说明"
              ></el-input>
            </el-form-item>
            <!-- 图片上传-->
            <el-form-item label="上传图片:" class="r-upload">
              <el-upload
                ref="pictureUpload" 
                list-type="picture-card"
                :file-list="fileList" 
                multiple  
                :auto-upload="false"
                limt=3
                action="#"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img class="el-upload-list__item-thumbnail" :src="file.url"/>
                  <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
            <!-- 提交 -->
            <el-form-item class="r-sumbit">
              <el-button @click="submitup()">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 订单详情 -->
        <div class="m-details">
          <div class="d-title">订单详情</div>
          <div class="d-content">
            <div class="d-img">
              <img :src="ordergoods.img"/>
            </div>
            <div class="d-goodsName">
              <div>
                {{ordergoods.goods_name}}
              </div>
              <div class="d-spec">
              </div>
            </div>
          </div>
          <div class="d-footer">
            <ul class="f-title">
              <li>卖家:</li>
              <li>订单编号:</li>
              <li>单价:</li>
              <li>邮费:</li>
              <li>商品总价:</li>
            </ul>
            <ul class="f-data">
              <li class="d-item">{{order.store_name}}</li>
              <li class="d-item">{{order.order_no}}</li>
              <li>￥{{ordergoods.goods_price}}*{{ordergoods.goods_num}}(数量)</li>
              <li>￥0.00</li>
              <li>￥{{ordergoods.goods_price*ordergoods.goods_num}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AfterSale,AfterSaleUp } from '@/api/order'
export default {
  name: "applyService",
  data() {
    return {
      id:0,
      oid:0,
      order:[],
      fileList: [],
      ordergoods:[],
      refundValue:"", // 仅退款原因
      bothValue:"",   // 退款退货原因
      reasontext:"",  // 退款说明
      refundSum:0,    // 退款金额
      refundOptions: [
        {
          value: 1,
          label: '不喜欢/不想要'
        },
          {
          value: 2,
          label: '空包裹'
        },
          {
          value: 3,
          label: '未按约定时间发货'
        },
          {
          value: 4,
          label: '快递/物流一直未送到'
        },
          {
          value: 5,
          label: '快递/物流无跟踪记录'
        },
          {
          value: 6,
          label: '货物破损已拒签'
        },
      ],//退款原因
      bothOptions: [
        {
          value: 1,
          label: '退运费'
        },
          {
          value: 2,
          label: '大小/尺寸与商品描述不符'
        },
          {
          value: 3,
          label: '颜色/型号/款式与商品描述不符'
        },
          {
          value: 4,
          label: '材质与商品描述不符'
        },
          {
          value: 5,
          label: '做工粗糙/有瑕疵'
        },
          {
          value: 6,
          label: '质量问题'
        },
          {
          value: 7,
          label: '配件问题'
        },
          {
          value: 8,
          label: '少件/漏发'
        },
          {
          value: 9,
          label: '包装/商品破损'
        },
          {
          value: 10,
          label: '卖家发错货'
        },
      ],//退款退货原因
      isShowRefund:1, // 1仅退款  2为退款退货
      loading:false,
      disabled:false,
      mes:'',
      rmes:''
    }
},
methods: {
  async submitup(){
    let formData = new FormData();
    if(!this.refundchange()) return false;
    if(!this.fromChange()) return false;
    formData.append("id",this.id);
    formData.append("oid",this.oid);
    formData.append("refundSum",this.refundSum);       // 退款金额
    formData.append("reasontext",this.reasontext);     // 退款说明
    formData.append("isShowRefund",this.isShowRefund); // 退款方式
    if(this.isShowRefund==1){formData.append("refund",this.refundValue);} // 退款原因
    if(this.isShowRefund==2){formData.append("refund",this.bothValue);}
    let uploadFiles = this.$refs.pictureUpload.uploadFiles;
   
    let i = 1;
    uploadFiles.forEach(item =>{
      formData.append("file"+i, item.raw);i++;
    })
    const {data: res} = await AfterSaleUp(formData);
    if (res.message == 'ok') {
      this.$router.push('/user/refund/details?id='+res.result)
    }
  },
  handleRemove(file) { // 相片墙图片删除
    let uploadFiles = this.$refs.pictureUpload.uploadFiles
    for (var i = 0; i < uploadFiles.length; i++) {
      if (uploadFiles[i]['url'] == file.url) {
        uploadFiles.splice(i, 1)
      }
    }
	},
  refundchange(){ // 退款原因验证
    if(this.isShowRefund==1){
      if(this.refundValue==""){this.rmes = "请选择退款原因"; return false;} 
    }
    if(this.isShowRefund==2){
      if(this.bothValue==""){this.rmes = "请选择退款原因"; return false;} 
    }
    this.rmes = ""; return true;
  },
  fromChange(){ // 退款金额验证
    let test=/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
    if(!(test.exec(this.refundSum)) ){
      this.mes="请输入正确的金额";
      return false;
    }
    if(this.refundSum>(this.ordergoods.goods_price*this.ordergoods.goods_num)){
      this.mes="退款金额须小于该商品实际支付金额";
      return false;
    }
    if(this.refundSum<0){
      this.mes="请输入正确的金额";
      return false;
    }
    this.mes="";
    return true;
  },
  handleRefund(){
    this.isShowRefund=1
  },
  handleBoth(){
    this.isShowRefund=2
  },
  async getInfo(){
    this.loading = true
    const {data: res} = await AfterSale({id:this.id,oid:this.oid});
    this.loading = false
    if(res.message=="ok"){
      this.order      = res.result.order;
      this.ordergoods = res.result.ordergoods;
      this.refundSum  = this.ordergoods.goods_price*this.ordergoods.goods_num
    }else this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');
  }
},
created() {
  this.id  = this.$route.query.id;
  this.oid = this.$route.query.oid;
  if(typeof this.id  !== 'number' && isNaN(this.id)){this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');return false;}
  if(typeof this.oid !== 'number' && isNaN(this.oid)){this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');return false;}
  this.getInfo();
}
}
</script>

<style lang="less" scoped>
.item__error_refund{
  padding-left: 10%;
}
.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 148px;
  height: 148px;
}
.apply-service {
  //   min-height: 600px;
  padding: 40px 0px;

  .s-container {
    width: 1000px;
    margin: 0 auto;

    .s-refund {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .r-item {
        width: 300px;
        height: 38px;
        background: #ccc;
        color: #fff;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
      }

      .i-hd {
        background: #e1251b;
      }
    }

    .s-goods {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .g-item {
        width: 225px;
        height: 38px;
        background: #ccc;
        color: #fff;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
      }

      .g-hd {
        background: #e1251b;
      }
    }

    .s-main {
      min-height: 600px;
      border: 1px solid #ddd;
      display: flex;

      .s-title {
        width: 100px;
        line-height: 76px;
        color: #666;
      }

      .m-content {
        border-right: 1px solid #ddd;
        padding: 20px;
        font-size: 14px;
        width: 750px;

        .c-goods {
          display: flex;
          // justify-content: space-between;
          height: 76px;
          //   border: 1px solid #e1251b;

          .g-content {
            height: 76px;
            display: flex;
            font-size: 12px;
            .c-img {
              margin-right: 10px;
              img {
                width: 76px;
                height: 76px;
              }
            }

            .c-text {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 13px;

              .t-middle {
                color: #666;
              }

              .t-price {
                strong {
                  color: #e1251b;
                }
              }
            }
          }
        }

        .c-service {
          display: flex;
          margin: 20px 0px;
          .c-title {
            width: 60px;
            height: 34px;
            line-height: 34px;
            margin-right: 10px;
            color: #666;
          }
          .s-content {
            display: flex;
            align-items: center;

            .s-item {
              width: 150px;
              height: 30px;
              text-align: center;
              line-height: 30px;
              cursor: pointer;
              margin-right: 20px;
              border: 2px solid #ddd;
              border-radius: 5px;

              &:hover {
                border: 2px solid #e1251b;
              }
            }

            .s-current {
              background: url("../../assets/images/opt.png") no-repeat;
              background-position: right bottom;
              border: 2px solid #e1251b;
            }
          }
        }

        .r-container {
          .r-price {
            display: flex;
            /deep/ .el-input__inner {
              width: 220px;
              color: #e1251b;
            }
          }
          .r-checkbox {
            display: flex;
            /deep/ .el-textarea__inner {
              width: 480px;
              height: 130px;
            }
          }
          .r-upload {
            /deep/ .el-upload {
              width: 88px;
              height: 88px;
              line-height: 88px;
            }
          }
          .r-sumbit {
            margin-left: 70px;
            .el-button {
              width: 200px;
              height: 34px;
              background: #e1251b;
              color: #fff;
              padding: 0px;
            }
          }
        }
      }

      .m-details {
        width: 250px;
        padding: 10px 20px;

        .d-title {
          line-height: 40px;
          font-size: 16px;
          border-bottom: 1px solid #ddd;
        }

        .d-content {
          height: 140px;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;

          .d-img {
            width: 54px;
            height: 54px;

            img {
              width: 54px;
              height: 54px;
            }
          }

          .d-goodsName {
            padding-left: 10px;

            .d-spec {
              margin-top: 5px;
              color: #666;
            }
          }
        }

        .d-footer {
          padding: 10px 0px;
          display: flex;
          font-size: 13px;

          .f-title {
            margin-right: 20px;

            li {
              color: #666;
            }
          }

          .f-data {
            .d-item {
              color: #0088cb;
            }
          }
        }
      }
    }
  }
}
</style>