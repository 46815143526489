import request from '@/libs/request'

/**
 * 获取评论1
 */
export function getGoodsReply(data) {
    return request({
        url: '/reply/replyway',
        method: 'post',
        data
    })
}

/**
 * 商品评论初始化
 */
export function getReplylistwayApi(data) {
    return request({
        url: '/goods/replyListWay',
        method: 'post',
        data
    })
}


/**
 * 提交评论
 */
export function getReplyWay(data) {
    return request({
        url: '/goods/replyway',
        method: 'post',
        data
    })
}


/**
 * 商品评论列表数据
 */
export function getEvaluateListApi(data) {
    return request({
        url: '/goods/showReply',
        method: 'post',
        data
    })
}

/**
 * 商品评论列表数据
 */
export function deleteEvaluateApi(data) {
    return request({
        url: '/goods/delReply',
        method: 'post',
        data
    })
}

/**
 * 商品详情评论数据
 */
export function getReplyListWay(data) {
    return request({
        url: '/goods/ReplyList',
        method: 'post',
        data
    })
}